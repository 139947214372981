.leaflet-control-attribution{
    display: none;
}

.leaflet-control-container >
.leaflet-top,
.leaflet-right {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
}

.space-children-horizontal > * {
    margin-left: 0.5em !important;
    margin-right: 0.5em !important;
}
.space-children-horizontal > *:first-child {
    margin-left: 0 !important;
}
.space-children-horizontal > *:last-child {
    margin-right: 0 !important;
}

.leaflet-control-layers-toggle {
    background-color: 'black';
}